import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import cookie from "discourse/lib/cookie";
import Group from "discourse/models/group";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let NewMessage = (_class = class NewMessage extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "dialog", _descriptor, this);
    _initializerDefineProperty(this, "composer", _descriptor2, this);
    _initializerDefineProperty(this, "router", _descriptor3, this);
  }
  beforeModel(transition) {
    const params = transition.to.queryParams;
    const userName = params.username;
    const groupName = params.groupname || params.group_name;
    if (!this.currentUser) {
      cookie("destination_url", window.location.href);
      this.router.replaceWith("login");
      return;
    }
    if (transition.from) {
      transition.abort();
      if (userName) {
        return this.openComposer(transition, userName);
      }
      if (groupName) {
        // send a message to a group
        return Group.messageable(groupName).then(result => {
          if (result.messageable) {
            this.openComposer(transition, groupName);
          } else {
            this.dialog.alert(I18n.t("composer.cant_send_pm", {
              username: groupName
            }));
          }
        }).catch(() => this.dialog.alert(I18n.t("composer.create_message_error")));
      }
      return this.openComposer(transition);
    } else {
      this.router.replaceWith("discovery.latest").followRedirects().then(() => {
        if (userName) {
          return this.openComposer(transition, userName);
        }
        if (groupName) {
          // send a message to a group
          return Group.messageable(groupName).then(result => {
            if (result.messageable) {
              this.openComposer(transition, groupName);
            } else {
              this.dialog.alert(I18n.t("composer.cant_send_pm", {
                username: groupName
              }));
            }
          }).catch(() => this.dialog.alert(I18n.t("composer.create_message_error")));
        }
        return this.openComposer(transition);
      });
    }
  }
  openComposer(transition, recipients) {
    next(() => {
      this.composer.openNewMessage({
        recipients,
        title: transition.to.queryParams.title,
        body: transition.to.queryParams.body
      });
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { NewMessage as default };