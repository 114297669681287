import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { service } from "@ember/service";
import { emojiUnescape } from "discourse/lib/text";
import { escapeExpression } from "discourse/lib/utilities";
import DiscourseRoute from "discourse/routes/discourse";
let UserActivityPending = (_class = class UserActivityPending extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  beforeModel() {
    this.username = this.modelFor("user").username_lower;
  }
  model() {
    return this.store.findAll("pending-post", {
      username: this.username
    }).then(pendingPosts => {
      for (let pendingPost of pendingPosts.content) {
        pendingPost.title = emojiUnescape(escapeExpression(pendingPost.title));
      }
      return pendingPosts;
    });
  }
  activate() {
    this.appEvents.on(`count-updated:${this.username}:pending_posts_count`, this, "_handleCountChange");
  }
  deactivate() {
    this.appEvents.off(`count-updated:${this.username}:pending_posts_count`, this, "_handleCountChange");
  }
  _handleCountChange(count) {
    this.refresh();
    if (count <= 0) {
      this.router.transitionTo("userActivity");
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { UserActivityPending as default };