import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor;
import EmberObject from "@ember/object";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import { url } from "discourse/lib/computed";
import UserAction from "discourse/models/user-action";
let UserPostsStream = (_dec = url("user.username_lower", "filter", "itemsLoaded", "/posts/%@/%@?offset=%@"), (_class = class UserPostsStream extends EmberObject {
  constructor() {
    super(...arguments);
    _defineProperty(this, "loaded", false);
    _defineProperty(this, "itemsLoaded", 0);
    _defineProperty(this, "canLoadMore", true);
    _defineProperty(this, "content", []);
    _initializerDefineProperty(this, "url", _descriptor, this);
  }
  filterBy(opts) {
    if (this.loaded && this.filter === opts.filter) {
      return Promise.resolve();
    }
    this.setProperties(Object.assign({
      itemsLoaded: 0,
      content: [],
      lastLoadedUrl: null
    }, opts));
    return this.findItems();
  }
  findItems() {
    if (this.loading || !this.canLoadMore) {
      return Promise.reject();
    }
    this.set("loading", true);
    return ajax(this.url).then(result => {
      if (result) {
        const posts = result.map(post => UserAction.create(post));
        this.content.pushObjects(posts);
        this.setProperties({
          loaded: true,
          itemsLoaded: this.itemsLoaded + posts.length,
          canLoadMore: posts.length > 0
        });
      }
    }).finally(() => this.set("loading", false));
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "url", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { UserPostsStream as default };