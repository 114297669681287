import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { action } from "@ember/object";
import { service } from "@ember/service";
import Tag from "discourse/models/tag";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let TagsIndex = (_class = class TagsIndex extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  model() {
    return this.store.findAll("tag").then(result => {
      if (result.extras) {
        if (result.extras.categories) {
          result.extras.categories.forEach(category => {
            category.tags = category.tags.map(t => Tag.create(t));
          });
        }
        if (result.extras.tag_groups) {
          result.extras.tag_groups.forEach(tagGroup => {
            tagGroup.tags = tagGroup.tags.map(t => Tag.create(t));
          });
        }
      }
      return result;
    });
  }
  titleToken() {
    return I18n.t("tagging.tags");
  }
  setupController(controller, model) {
    this.controllerFor("tags.index").setProperties({
      model,
      sortProperties: this.siteSettings.tags_sort_alphabetically ? ["id"] : ["totalCount:desc", "id"]
    });
  }
  showTagGroups() {
    this.router.transitionTo("tagGroups");
    return true;
  }
  triggerRefresh() {
    this.refresh();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "showTagGroups", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showTagGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRefresh"), _class.prototype)), _class);
export { TagsIndex as default };