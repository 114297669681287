import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { action } from "@ember/object";
import { service } from "@ember/service";
import $ from "jquery";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import Bookmark from "discourse/models/bookmark";
import Site from "discourse/models/site";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let UserActivityBookmarks = (_class = class UserActivityBookmarks extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "historyStore", _descriptor, this);
    _defineProperty(this, "templateName", "user/bookmarks");
    _defineProperty(this, "queryParams", {
      acting_username: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      }
    });
  }
  model(params) {
    const controller = this.controllerFor("user-activity-bookmarks");
    if (this.historyStore.isPoppedState && this.session.bookmarksModel && this.session.bookmarksModel.searchTerm === params.q) {
      return Promise.resolve(this.session.bookmarksModel);
    }
    this.session.setProperties({
      bookmarksModel: null
    });
    controller.set("loading", true);
    return this._loadBookmarks(params).then(async response => {
      if (!response.user_bookmark_list) {
        return {
          bookmarks: []
        };
      }
      response.user_bookmark_list.categories?.forEach(category => Site.current().updateCategory(category));
      const bookmarks = response.user_bookmark_list.bookmarks.map(controller.transform);
      await Bookmark.applyTransformations(bookmarks);
      const loadMoreUrl = response.user_bookmark_list.more_bookmarks_url;
      const model = {
        bookmarks,
        loadMoreUrl
      };
      this.session.set("bookmarksModel", model);
      return model;
    }).catch(() => controller.set("permissionDenied", true)).finally(() => controller.set("loading", false));
  }
  titleToken() {
    return I18n.t("user_action_groups.3");
  }
  triggerRefresh() {
    this.refresh();
  }
  _loadBookmarks(params) {
    let url = `/u/${this.modelFor("user").username}/bookmarks.json`;
    if (params) {
      url += "?" + $.param(params);
    }
    return ajax(url);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "historyStore", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRefresh"), _class.prototype)), _class);
export { UserActivityBookmarks as default };