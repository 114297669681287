import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
/* import __COLOCATED_TEMPLATE__ from './login.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { schedule } from "@ember/runloop";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import cookie, { removeCookie } from "discourse/lib/cookie";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import { areCookiesEnabled } from "discourse/lib/utilities";
import { wavingHandURL } from "discourse/lib/waving-hand-url";
import { getPasskeyCredential, isWebauthnSupported } from "discourse/lib/webauthn";
import { findAll } from "discourse/models/login-method";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import escape from "discourse-common/lib/escape";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import ForgotPassword from "./forgot-password";
import TEMPLATE from "./login.hbs";
import { setComponentTemplate } from "@ember/component";
let Login = (_class = class Login extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "capabilities", _descriptor, this);
    _initializerDefineProperty(this, "dialog", _descriptor2, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    _initializerDefineProperty(this, "site", _descriptor4, this);
    _initializerDefineProperty(this, "login", _descriptor5, this);
    _initializerDefineProperty(this, "modal", _descriptor6, this);
    _initializerDefineProperty(this, "loggingIn", _descriptor7, this);
    _initializerDefineProperty(this, "loggedIn", _descriptor8, this);
    _initializerDefineProperty(this, "showLoginButtons", _descriptor9, this);
    _initializerDefineProperty(this, "showSecondFactor", _descriptor10, this);
    _initializerDefineProperty(this, "loginPassword", _descriptor11, this);
    _initializerDefineProperty(this, "loginName", _descriptor12, this);
    _initializerDefineProperty(this, "flash", _descriptor13, this);
    _initializerDefineProperty(this, "flashType", _descriptor14, this);
    _initializerDefineProperty(this, "canLoginLocal", _descriptor15, this);
    _initializerDefineProperty(this, "canLoginLocalWithEmail", _descriptor16, this);
    _initializerDefineProperty(this, "secondFactorMethod", _descriptor17, this);
    _initializerDefineProperty(this, "securityKeyCredential", _descriptor18, this);
    _initializerDefineProperty(this, "otherMethodAllowed", _descriptor19, this);
    _initializerDefineProperty(this, "secondFactorRequired", _descriptor20, this);
    _initializerDefineProperty(this, "backupEnabled", _descriptor21, this);
    _initializerDefineProperty(this, "totpEnabled", _descriptor22, this);
    _initializerDefineProperty(this, "showSecurityKey", _descriptor23, this);
    _initializerDefineProperty(this, "securityKeyChallenge", _descriptor24, this);
    _initializerDefineProperty(this, "securityKeyAllowedCredentialIds", _descriptor25, this);
    _initializerDefineProperty(this, "secondFactorToken", _descriptor26, this);
  }
  get awaitingApproval() {
    return this.args.model.awaitingApproval && !this.canLoginLocal && !this.canLoginLocalWithEmail;
  }
  get loginDisabled() {
    return this.loggingIn || this.loggedIn;
  }
  get wavingHandURL() {
    return wavingHandURL();
  }
  get modalBodyClasses() {
    const classes = ["login-modal-body"];
    if (this.awaitingApproval) {
      classes.push("awaiting-approval");
    }
    if (this.hasAtLeastOneLoginButton && !this.showSecondFactor && !this.showSecurityKey) {
      classes.push("has-alt-auth");
    }
    if (!this.canLoginLocal) {
      classes.push("no-local-login");
    }
    if (this.showSecondFactor || this.showSecurityKey) {
      classes.push("second-factor");
    }
    return classes.join(" ");
  }
  get canUsePasskeys() {
    return this.siteSettings.enable_local_logins && this.siteSettings.enable_passkeys && isWebauthnSupported();
  }
  get hasAtLeastOneLoginButton() {
    return findAll().length > 0 || this.canUsePasskeys;
  }
  get hasNoLoginOptions() {
    return !this.hasAtLeastOneLoginButton && !this.canLoginLocal;
  }
  get loginButtonLabel() {
    return this.loggingIn ? "login.logging_in" : "login.title";
  }
  get showSignupLink() {
    return this.args.model.canSignUp && !this.loggingIn && !this.showSecondFactor;
  }
  get adminLoginPath() {
    return getURL("/u/admin-login");
  }
  async passkeyLogin() {
    let mediation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "optional";
    try {
      const publicKeyCredential = await getPasskeyCredential(e => this.dialog.alert(e), mediation, this.capabilities.isFirefox);
      if (publicKeyCredential) {
        const authResult = await ajax("/session/passkey/auth.json", {
          type: "POST",
          data: {
            publicKeyCredential
          }
        });
        if (authResult && !authResult.error) {
          const destinationUrl = cookie("destination_url");
          if (destinationUrl) {
            removeCookie("destination_url");
            window.location.assign(destinationUrl);
          } else {
            window.location.reload();
          }
        } else {
          this.dialog.alert(authResult.error);
        }
      }
    } catch (e) {
      popupAjaxError(e);
    }
  }
  preloadLogin() {
    const prefillUsername = document.querySelector("#hidden-login-form input[name=username]")?.value;
    if (prefillUsername) {
      this.loginName = prefillUsername;
      this.loginPassword = document.querySelector("#hidden-login-form input[name=password]").value;
    } else if (cookie("email")) {
      this.loginName = cookie("email");
    }
  }
  securityKeyCredentialChanged(value) {
    this.securityKeyCredential = value;
  }
  flashChanged(value) {
    this.flash = value;
  }
  flashTypeChanged(value) {
    this.flashType = value;
  }
  loginNameChanged(event) {
    this.loginName = event.target.value;
  }
  async triggerLogin() {
    if (this.loginDisabled) {
      return;
    }
    if (isEmpty(this.loginName) || isEmpty(this.loginPassword)) {
      this.flash = I18n.t("login.blank_username_or_password");
      this.flashType = "error";
      return;
    }
    try {
      this.loggingIn = true;
      const result = await ajax("/session", {
        type: "POST",
        data: {
          login: this.loginName,
          password: this.loginPassword,
          second_factor_token: this.securityKeyCredential || this.secondFactorToken,
          second_factor_method: this.secondFactorMethod,
          timezone: moment.tz.guess()
        }
      });
      if (result && result.error) {
        this.loggingIn = false;
        this.flash = null;
        if ((result.security_key_enabled || result.totp_enabled) && !this.secondFactorRequired) {
          this.otherMethodAllowed = result.multiple_second_factor_methods;
          this.secondFactorRequired = true;
          this.showLoginButtons = false;
          this.backupEnabled = result.backup_enabled;
          this.totpEnabled = result.totp_enabled;
          this.showSecondFactor = result.totp_enabled;
          this.showSecurityKey = result.security_key_enabled;
          this.secondFactorMethod = result.security_key_enabled ? SECOND_FACTOR_METHODS.SECURITY_KEY : SECOND_FACTOR_METHODS.TOTP;
          this.securityKeyChallenge = result.challenge;
          this.securityKeyAllowedCredentialIds = result.allowed_credential_ids;

          // only need to focus the 2FA input for TOTP
          if (!this.showSecurityKey) {
            schedule("afterRender", () => document.getElementById("second-factor").querySelector("input").focus());
          }
          return;
        } else if (result.reason === "not_activated") {
          this.args.model.showNotActivated({
            username: this.loginName,
            sentTo: escape(result.sent_to_email),
            currentEmail: escape(result.current_email)
          });
        } else if (result.reason === "suspended") {
          this.args.closeModal();
          this.dialog.alert(result.error);
        } else if (result.reason === "expired") {
          this.flash = htmlSafe(I18n.t("login.password_expired", {
            reset_url: getURL("/password-reset")
          }));
          this.flashType = "error";
        } else {
          this.flash = result.error;
          this.flashType = "error";
        }
      } else {
        this.loggedIn = true;
        // Trigger the browser's password manager using the hidden static login form:
        const hiddenLoginForm = document.getElementById("hidden-login-form");
        const applyHiddenFormInputValue = (value, key) => {
          if (!hiddenLoginForm) {
            return;
          }
          hiddenLoginForm.querySelector(`input[name=${key}]`).value = value;
        };
        const destinationUrl = cookie("destination_url");
        const ssoDestinationUrl = cookie("sso_destination_url");
        applyHiddenFormInputValue(this.loginName, "username");
        applyHiddenFormInputValue(this.loginPassword, "password");
        if (ssoDestinationUrl) {
          removeCookie("sso_destination_url");
          window.location.assign(ssoDestinationUrl);
          return;
        } else if (destinationUrl) {
          // redirect client to the original URL
          removeCookie("destination_url");
          applyHiddenFormInputValue(destinationUrl, "redirect");
        } else {
          applyHiddenFormInputValue(window.location.href, "redirect");
        }
        if (hiddenLoginForm) {
          if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) && navigator.userAgent.match(/Safari/g)) {
            // In case of Safari on iOS do not submit hidden login form
            window.location.href = hiddenLoginForm.querySelector("input[name=redirect]").value;
          } else {
            hiddenLoginForm.submit();
          }
        }
        return;
      }
    } catch (e) {
      // Failed to login
      if (e.jqXHR && e.jqXHR.status === 429) {
        this.flash = I18n.t("login.rate_limit");
        this.flashType = "error";
      } else if (e.jqXHR && e.jqXHR.status === 503 && e.jqXHR.responseJSON.error_type === "read_only") {
        this.flash = I18n.t("read_only_mode.login_disabled");
        this.flashType = "error";
      } else if (!areCookiesEnabled()) {
        this.flash = I18n.t("login.cookies_error");
        this.flashType = "error";
      } else {
        this.flash = I18n.t("login.error");
        this.flashType = "error";
      }
      this.loggingIn = false;
    }
  }
  async externalLoginAction(loginMethod) {
    if (this.loginDisabled) {
      return;
    }
    this.login.externalLogin(loginMethod, {
      signup: false,
      setLoggingIn: value => this.loggingIn = value
    });
  }
  createAccount() {
    let createAccountProps = {};
    if (this.loginName && this.loginName.indexOf("@") > 0) {
      createAccountProps.accountEmail = this.loginName;
      createAccountProps.accountUsername = null;
    } else {
      createAccountProps.accountUsername = this.loginName;
      createAccountProps.accountEmail = null;
    }
    this.args.model.showCreateAccount(createAccountProps);
  }
  interceptResetLink(event) {
    if (!wantsNewWindow(event) && event.target.href && new URL(event.target.href).pathname === getURL("/password-reset")) {
      event.preventDefault();
      event.stopPropagation();
      this.modal.show(ForgotPassword, {
        model: {
          emailOrUsername: this.loginName
        }
      });
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "login", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loggingIn", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loggedIn", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showLoginButtons", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showSecondFactor", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "loginPassword", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "loginName", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.flash;
  }
}), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "flashType", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.flashType;
  }
}), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "canLoginLocal", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.siteSettings.enable_local_logins;
  }
}), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "canLoginLocalWithEmail", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.siteSettings.enable_local_logins_via_email;
  }
}), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "secondFactorMethod", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return SECOND_FACTOR_METHODS.TOTP;
  }
}), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "securityKeyCredential", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "otherMethodAllowed", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "secondFactorRequired", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "backupEnabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "totpEnabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "showSecurityKey", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "securityKeyChallenge", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "securityKeyAllowedCredentialIds", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "secondFactorToken", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "passkeyLogin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "passkeyLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preloadLogin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "preloadLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "securityKeyCredentialChanged", [action], Object.getOwnPropertyDescriptor(_class.prototype, "securityKeyCredentialChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flashChanged", [action], Object.getOwnPropertyDescriptor(_class.prototype, "flashChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flashTypeChanged", [action], Object.getOwnPropertyDescriptor(_class.prototype, "flashTypeChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loginNameChanged", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loginNameChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "triggerLogin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "externalLoginAction", [action], Object.getOwnPropertyDescriptor(_class.prototype, "externalLoginAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createAccount", [action], Object.getOwnPropertyDescriptor(_class.prototype, "createAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "interceptResetLink", [action], Object.getOwnPropertyDescriptor(_class.prototype, "interceptResetLink"), _class.prototype)), _class);
export { Login as default };
setComponentTemplate(TEMPLATE, Login);