import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { action } from "@ember/object";
import { service } from "@ember/service";
import Composer from "discourse/models/composer";
import Draft from "discourse/models/draft";
import DiscourseRoute from "discourse/routes/discourse";
let UserPrivateMessages = (_class = class UserPrivateMessages extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "composer", _descriptor, this);
    _defineProperty(this, "templateName", "user/messages");
  }
  afterModel() {
    this.pmTopicTrackingState.startTracking();
  }
  setupController() {
    super.setupController(...arguments);
    if (this.currentUser) {
      Draft.get("new_private_message").then(data => {
        if (data.draft) {
          this.composer.open({
            draft: data.draft,
            draftKey: Composer.NEW_PRIVATE_MESSAGE_KEY,
            ignoreIfChanged: true,
            draftSequence: data.draft_sequence
          });
        }
      });
    }
  }
  triggerRefresh() {
    this.refresh();
  }
  willTransition() {
    super.willTransition(...arguments);
    return true;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRefresh"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willTransition", [action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class);
export { UserPrivateMessages as default };