import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { service } from "@ember/service";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let Users = (_class = class Users extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    _defineProperty(this, "queryParams", {
      period: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      },
      name: {
        refreshModel: false,
        replace: true
      },
      group: {
        refreshModel: true
      },
      exclude_groups: {
        refreshModel: true
      },
      exclude_usernames: {
        refreshModel: true
      }
    });
  }
  titleToken() {
    return I18n.t("directory.title");
  }
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        period: "weekly",
        order: "likes_received",
        asc: null,
        name: "",
        group: null,
        exclude_usernames: null,
        exclude_groups: null,
        lastUpdatedAt: null
      });
    }
  }
  beforeModel() {
    if (this.siteSettings.hide_user_profiles_from_public && !this.currentUser) {
      this.router.replaceWith("discovery");
    }
  }
  model(params) {
    return ajax("/directory-columns.json").then(response => {
      params.order = params.order || response.directory_columns[0]?.name || "likes_received";
      return {
        params,
        columns: response.directory_columns
      };
    }).catch(popupAjaxError);
  }
  setupController(controller, model) {
    controller.set("columns", model.columns);
    return Promise.all([controller.loadGroups(), controller.loadUsers(model.params)]);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { Users as default };