import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import Category from "discourse/models/category";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let About = (_class = class About extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
  }
  async model() {
    const result = await ajax("/about.json");
    const users = Object.fromEntries(result.users.map(user => [user.id, user]));
    result.categories?.forEach(category => {
      this.site.updateCategory(category);
    });
    const yearAgo = moment().utc().subtract(1, "year");
    result.about.admins = result.about.admin_ids.map(id => users[id]).filter(r => moment(r.last_seen_at) > yearAgo);
    result.about.moderators = result.about.moderator_ids.map(id => users[id]).filter(r => moment(r.last_seen_at) > yearAgo);
    result.about.category_moderators?.forEach(obj => {
      obj.category = Category.findById(obj.category_id);
      obj.moderators = obj.moderator_ids.map(id => users[id]);
    });
    return result.about;
  }
  titleToken() {
    return I18n.t("about.simple_title");
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { About as default };