import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import AssociateAccountConfirm from "discourse/components/modal/associate-account-confirm";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import DiscourseRoute from "discourse/routes/discourse";
let AssociateAccount = (_class = class AssociateAccount extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "modal", _descriptor3, this);
  }
  beforeModel(transition) {
    if (!this.currentUser) {
      cookie("destination_url", transition.intent.url);
      return this.router.replaceWith("login");
    }
    const params = this.paramsFor("associate-account");
    this.redirectToAccount(params);
  }
  async redirectToAccount(params) {
    await this.router.replaceWith(`preferences.account`, this.currentUser).followRedirects();
    next(() => this.showAssociateAccount(params));
  }
  async showAssociateAccount(params) {
    try {
      const model = await ajax(`/associate/${encodeURIComponent(params.token)}.json`);
      this.modal.show(AssociateAccountConfirm, {
        model
      });
    } catch (e) {
      popupAjaxError(e);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "redirectToAccount", [action], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showAssociateAccount", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showAssociateAccount"), _class.prototype)), _class);
export { AssociateAccount as default };