import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import UserAction from "discourse/models/user-action";
import UserTopicListRoute from "discourse/routes/user-topic-list";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
let UserActivityTopics = (_class = class UserActivityTopics extends UserTopicListRoute {
  constructor() {
    super(...arguments);
    _defineProperty(this, "userActionType", UserAction.TYPES.topics);
  }
  model() {
    let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return this.store.findFiltered("topicList", {
      filter: "topics/created-by/" + this.modelFor("user").get("username_lower"),
      params
    }).then(model => {
      // andrei: we agreed that this is an anti pattern,
      // it's better to avoid mutating a rest model like this
      // this place we'll be refactored later
      // see https://github.com/discourse/discourse/pull/14313#discussion_r708784704
      model.set("emptyState", this.emptyState());
      return model;
    });
  }
  emptyState() {
    const user = this.modelFor("user");
    let title, body;
    if (this.isCurrentUser(user)) {
      title = I18n.t("user_activity.no_topics_title");
      body = htmlSafe(I18n.t("user_activity.no_topics_body", {
        searchUrl: getURL("/search")
      }));
    } else {
      title = I18n.t("user_activity.no_topics_title_others", {
        username: user.username
      });
      body = "";
    }
    return {
      title,
      body
    };
  }
  titleToken() {
    return I18n.t("user_action_groups.4");
  }
  triggerRefresh() {
    this.refresh();
  }
}, (_applyDecoratedDescriptor(_class.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRefresh"), _class.prototype)), _class);
export { UserActivityTopics as default };