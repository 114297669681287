import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import Helper from "@ember/component/helper";
import { scheduleOnce } from "@ember/runloop";
import { service } from "@ember/service";
let HideApplicationHeaderButtons = (_class = class HideApplicationHeaderButtons extends Helper {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "header", _descriptor, this);
  }
  registerHider(buttons) {
    this.header.registerHider(this, buttons);
  }
  compute(_ref) {
    let [...buttons] = _ref;
    scheduleOnce("afterRender", this, this.registerHider, buttons);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "header", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { HideApplicationHeaderButtons as default };