import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
import { action } from "@ember/object";
import { service } from "@ember/service";
import { RouteException } from "discourse/controllers/exception";
import User from "discourse/models/user";
import DiscourseRoute from "discourse/routes/discourse";
import { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
let UserRoute = (_dec = service("search"), (_class = class UserRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "searchService", _descriptor2, this);
    _initializerDefineProperty(this, "appEvents", _descriptor3, this);
    _initializerDefineProperty(this, "messageBus", _descriptor4, this);
  }
  beforeModel() {
    if (this.siteSettings.hide_user_profiles_from_public && !this.currentUser) {
      throw new RouteException({
        status: 403,
        desc: I18n.t("user.login_to_view_profile")
      });
    }
  }
  model(params) {
    // If we're viewing the currently logged in user, return that object instead
    if (this.currentUser && params.username.toLowerCase() === this.currentUser.username_lower) {
      return this.currentUser;
    }
    return User.create({
      username: encodeURIComponent(params.username)
    });
  }
  afterModel() {
    const user = this.modelFor("user");
    return user.findDetails().then(() => user.findStaffInfo()).then(() => user.statusManager.trackStatus()).catch(() => this.router.replaceWith("/404"));
  }
  serialize(model) {
    if (!model) {
      return {};
    }
    return {
      username: (model.username || "").toLowerCase()
    };
  }
  setupController(controller, user) {
    controller.set("model", user);
    this.searchService.searchContext = user.searchContext;
  }
  activate() {
    super.activate(...arguments);
    const user = this.modelFor("user");
    this.messageBus.subscribe(`/u/${user.username_lower}`, this.onUserMessage);
    this.messageBus.subscribe(`/u/${user.username_lower}/counters`, this.onUserCountersMessage);
  }
  deactivate() {
    super.deactivate(...arguments);
    const user = this.modelFor("user");
    this.messageBus.unsubscribe(`/u/${user.username_lower}`, this.onUserMessage);
    this.messageBus.unsubscribe(`/u/${user.username_lower}/counters`, this.onUserCountersMessage);
    user.statusManager.stopTrackingStatus();

    // Remove the search context
    this.searchService.searchContext = null;
  }
  onUserMessage(data) {
    const user = this.modelFor("user");
    return user.loadUserAction(data);
  }
  onUserCountersMessage(data) {
    const user = this.modelFor("user");
    user.setProperties(data);
    Object.entries(data).forEach(_ref => {
      let [key, value] = _ref;
      return this.appEvents.trigger(`count-updated:${user.username_lower}:${key}`, value);
    });
  }
  titleToken() {
    const username = this.modelFor("user").username;
    return username ? username : null;
  }
  undoRevokeApiKey(key) {
    key.undoRevoke();
  }
  revokeApiKey(key) {
    key.revoke();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "onUserMessage", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "onUserMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUserCountersMessage", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "onUserCountersMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "undoRevokeApiKey", [action], Object.getOwnPropertyDescriptor(_class.prototype, "undoRevokeApiKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "revokeApiKey", [action], Object.getOwnPropertyDescriptor(_class.prototype, "revokeApiKey"), _class.prototype)), _class));
export { UserRoute as default };