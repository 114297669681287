import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import Route from "@ember/routing/route";
import { service } from "@ember/service";
let GroupActivityIndex = (_class = class GroupActivityIndex extends Route {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  beforeModel() {
    const group = this.modelFor("group");
    if (group.can_see_members) {
      this.router.transitionTo("group.activity.posts");
    } else {
      this.router.transitionTo("group.activity.mentions");
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { GroupActivityIndex as default };