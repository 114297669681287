import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { service } from "@ember/service";
import Topic, { ID_CONSTRAINT } from "discourse/models/topic";
import DiscourseRoute from "discourse/routes/discourse";
let TopicBySlugOrId = (_class = class TopicBySlugOrId extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  model(params) {
    if (params.slug_or_id.match(ID_CONSTRAINT)) {
      return {
        url: `/t/topic/${params.slug_or_id}`
      };
    } else {
      return Topic.idForSlug(params.slug_or_id).then(data => {
        return {
          url: `/t/${data.slug}/${data.topic_id}`
        };
      });
    }
  }
  afterModel(result) {
    this.router.transitionTo(result.url);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { TopicBySlugOrId as default };