import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { buildPermissionDescription } from "discourse/models/permission-type";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let GroupPermissions = (_class = class GroupPermissions extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  titleToken() {
    return I18n.t("groups.permissions.title");
  }
  model() {
    let group = this.modelFor("group");
    return ajax(`/g/${group.name}/permissions`).then(permissions => {
      permissions.forEach(permission => {
        permission.description = buildPermissionDescription(permission.permission_type);
      });
      return {
        permissions
      };
    }).catch(() => {
      this.router.transitionTo("group.members", group);
    });
  }
  setupController(controller, model) {
    this.controllerFor("group-permissions").setProperties({
      model
    });
    this.controllerFor("group").set("showing", "permissions");
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { GroupPermissions as default };