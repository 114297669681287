import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import { action, get } from "@ember/object";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export function buildGroupPage(type) {
  var _class;
  return _class = class GroupActivityPosts extends DiscourseRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "type", type);
      _defineProperty(this, "templateName", "group-activity-posts");
      _defineProperty(this, "controllerName", "group-activity-posts");
    }
    titleToken() {
      return I18n.t(`groups.${type}`);
    }
    model(params, transition) {
      let categoryId = get(transition.to, "queryParams.category_id");
      return this.modelFor("group").findPosts({
        type,
        categoryId
      });
    }
    setupController(controller, model) {
      let loadedAll = model.length < 20;
      controller.setProperties({
        model,
        type,
        canLoadMore: !loadedAll
      });
    }
    didTransition() {
      return true;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "didTransition", [action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class;
}
export default buildGroupPage("posts");