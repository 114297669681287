import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let GroupManage = (_class = class GroupManage extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  titleToken() {
    return I18n.t("groups.manage.title");
  }
  model() {
    return this.modelFor("group");
  }
  afterModel(group) {
    if (!this.currentUser || !(this.modelFor("group").can_admin_group && group.get("automatic")) && !this.currentUser.canManageGroup(group)) {
      this.router.transitionTo("group.members", group);
    }
  }
  setupController(controller, model) {
    this.controllerFor("group-manage").setProperties({
      model
    });
    this.controllerFor("group").set("showing", "manage");
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { GroupManage as default };