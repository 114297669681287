import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { action } from "@ember/object";
import { service } from "@ember/service";
import AvatarSelectorModal from "discourse/components/modal/avatar-selector";
import UserBadge from "discourse/models/user-badge";
import RestrictedUserRoute from "discourse/routes/restricted-user";
import I18n from "discourse-i18n";
let PreferencesAccount = (_class = class PreferencesAccount extends RestrictedUserRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
  }
  model() {
    const user = this.modelFor("user");
    if (this.siteSettings.enable_badges) {
      return UserBadge.findByUsername(user.get("username")).then(userBadges => {
        user.set("badges", userBadges.map(ub => ub.badge));
        return user;
      });
    } else {
      return user;
    }
  }
  setupController(controller, user) {
    controller.reset();
    controller.setProperties({
      model: user,
      newNameInput: user.get("name"),
      newTitleInput: user.get("title"),
      newPrimaryGroupInput: user.get("primary_group_id"),
      newFlairGroupId: user.get("flair_group_id"),
      newStatus: user.status,
      subpageTitle: I18n.t("user.preferences_nav.account")
    });
  }
  showAvatarSelector(user) {
    this.modal.show(AvatarSelectorModal, {
      model: {
        user
      }
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "showAvatarSelector", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showAvatarSelector"), _class.prototype)), _class);
export { PreferencesAccount as default };