import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { action } from "@ember/object";
import { service } from "@ember/service";
import RestrictedUserRoute from "discourse/routes/restricted-user";
let PreferencesSecondFactor = (_class = class PreferencesSecondFactor extends RestrictedUserRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "router", _descriptor3, this);
  }
  model() {
    return this.modelFor("user");
  }
  setupController(controller, model) {
    controller.setProperties({
      model,
      newUsername: model.username
    });
    controller.set("loading", true);
    model.loadSecondFactorCodes().then(response => {
      if (response.error) {
        controller.set("errorMessage", response.error);
      } else if (response.unconfirmed_session) {
        this.router.transitionTo("preferences.security");
      } else {
        controller.setProperties({
          errorMessage: null,
          totps: response.totps,
          security_keys: response.security_keys
        });
      }
    }).catch(controller.popupAjaxError).finally(() => controller.set("loading", false));
  }
  willTransition(transition) {
    super.willTransition(...arguments);
    if (transition.targetName === "preferences.second-factor" || !this.currentUser || this.currentUser.is_anonymous || this.currentUser.second_factor_enabled || this.siteSettings.enforce_second_factor === "staff" && !this.currentUser.staff || this.siteSettings.enforce_second_factor === "no") {
      return true;
    }
    transition.abort();
    return false;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "willTransition", [action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class);
export { PreferencesSecondFactor as default };