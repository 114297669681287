import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { action } from "@ember/object";
import { service } from "@ember/service";
import CreateInvite from "discourse/components/modal/create-invite";
import GroupAddMembersModal from "discourse/components/modal/group-add-members";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let GroupIndex = (_class = class GroupIndex extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
  }
  titleToken() {
    return I18n.t("groups.members.title");
  }
  model(params) {
    this._params = params;
    return this.modelFor("group");
  }
  setupController(controller, model) {
    controller.setProperties({
      model,
      filterInput: this._params.filter,
      showing: "members"
    });
    controller.reloadMembers(true);
  }
  showAddMembersModal() {
    this.modal.show(GroupAddMembersModal, {
      model: this.modelFor("group")
    });
  }
  showInviteModal() {
    const group = this.modelFor("group");
    this.modal.show(CreateInvite, {
      model: {
        groupIds: [group.id]
      }
    });
  }
  didTransition() {
    this.controllerFor("group-index").set("filterInput", this._params.filter);
    return true;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "showAddMembersModal", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showAddMembersModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showInviteModal", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showInviteModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTransition", [action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class);
export { GroupIndex as default };