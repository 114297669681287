import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { action } from "@ember/object";
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let GroupMessages = (_class = class GroupMessages extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  titleToken() {
    return I18n.t("groups.messages");
  }
  model() {
    return this.modelFor("group");
  }
  afterModel(group) {
    if (!group.get("is_group_user") && !(this.currentUser && this.currentUser.admin)) {
      this.router.transitionTo("group.members", group);
    }
  }
  triggerRefresh() {
    this.refresh();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRefresh"), _class.prototype)), _class);
export { GroupMessages as default };