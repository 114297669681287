import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _AuthButtons;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let AuthButtons = (_class = (_AuthButtons = class AuthButtons extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "header", _descriptor, this);
  }
  get showSignupButton() {
    return this.args.canSignUp && !this.header.headerButtonsHidden.includes("signup") && !this.header.topic;
  }
  get showLoginButton() {
    return !this.header.headerButtonsHidden.includes("login");
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <span class="auth-buttons">
      {{#if this.showSignupButton}}
        <DButton
          class="btn-primary btn-small sign-up-button"
          @action={{@showCreateAccount}}
          @label="sign_up"
        />
      {{/if}}

      {{#if this.showLoginButton}}
        <DButton
          class="btn-primary btn-small login-button"
          @action={{@showLogin}}
          @label="log_in"
          @icon="user"
        />
      {{/if}}
    </span>
  
*/
{
  "id": "RsH2KzG3",
  "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"auth-buttons\"],[12],[1,\"\\n\"],[41,[30,0,[\"showSignupButton\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-primary btn-small sign-up-button\"]],[[\"@action\",\"@label\"],[[30,1],\"sign_up\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showLoginButton\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-primary btn-small login-button\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,2],\"log_in\",\"user\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@showCreateAccount\",\"@showLogin\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/auth-buttons.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), _AuthButtons), _AuthButtons), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "header", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { AuthButtons as default };