import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import UserAction from "discourse/models/user-action";
import UserActivityStreamRoute from "discourse/routes/user-activity-stream";
import I18n from "discourse-i18n";
export default class UserNotificationsLinks extends UserActivityStreamRoute {
  constructor() {
    super(...arguments);
    _defineProperty(this, "userActionType", UserAction.TYPES["links"]);
  }
  titleToken() {
    return I18n.t("user_action_groups.17");
  }
}